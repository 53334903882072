import { title, mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const featuresStyle = {
  featuresSection: {
    padding: "20px 0px"
  },
  textCenter: {
    textAlign: "center"
  },
  title,
  mlAuto,
  mrAuto,
  titlep:{
    ...title,
    fontSize:'18px',
    marginTop:'5px',
    marginBottom:'5px'
  },
  descriptionp:{
    fontWeight:400,
    fontSize:'16px',
    marginBottom:'5px'
  }
};

export default featuresStyle;
